import { graphql, StaticQuery } from "gatsby"
import parse from "html-react-parser"
import React, { useState } from "react"
import { Alert, Col, Container, Row } from "react-bootstrap"
import LazyLoad from "react-lazyload"
import CTA from "../components/common/CTA"
import { InPageDropDown } from "../components/common/dropdown-component/makeDropdown"
import Frame from "../components/common/frame"
import SidebarSmall from "../components/common/sidebar-small"
import Navigation from "../components/navigation"
import Slide from "../components/utility/slide_content"
import "./../styles/pages/branding.scss"

function Branding() {
  const [copiedText, setCopiedText] = useState(null)
  const [showAlert, setShowAlert] = useState(false)

  function resetAlert() {
    setShowAlert(false)
  }

  const copyToClipboard = i => {
    setCopiedText(i)
    navigator.clipboard.writeText(i)
    setShowAlert(true)
    setTimeout(function() {
      resetAlert()
    }, 2000)
  }

  const ColorCards = props => {
    const colorCode = props.items.slug
    const name = props.items.linkName
    const value = props.items.authorName
    return colorCode.map((el, idx) => {
      return (
        <Col lg={props.lg ? props.lg : 6} xs={6} md={props.md ? props.md : 4}>
          <div
            className={`color-card ${props.className}`}
            id={idx}
            onClick={() => copyToClipboard(el)}
          >
            <div className="top" style={{ background: `${el}` }}>
              <p className="value">{value[idx]}</p>
            </div>
            <div className="bottom">
              <p className="color-name">{name[idx]}</p>
              <p className="color-code">{el}</p>
            </div>
          </div>
        </Col>
      )
    })
  }

  const ImageCard = props => {
    const img = props.items.icon
    const name = props.items.linkName
    const background = props.items.slug

    return name.map((el, idx) => {
      return (
        <Col lg={4} md={4}>
          <div className="img-card">
            <LazyLoad throttle={200} height={200}>
              {props.isBackground ? (
                <div
                  className="img-wrapper red"
                  style={{ backgroundImage: `url(${background[idx]})` }}
                >
                  <img src={img[idx].url} alt="img" className="dos-image" />
                </div>
              ) : (
                <div
                  className="img-wrapper"
                  style={{ backgroundColor: background[idx] }}
                >
                  <img src={img[idx].url} alt="img" className="dos-image" />
                </div>
              )}
            </LazyLoad>
            <div className="p14">
              <p>{el}</p>
            </div>
          </div>
        </Col>
      )
    })
  }

  const Alerts = () => {
    return (
      <>
        <Alert variant="primary" className="position-relative">
          <p className="mb-0">
            {copiedText} <span className="hyphen">-</span>{" "}
            <span className="text-light-purple">
              Hex code copied to clipboard
            </span>
          </p>
        </Alert>
      </>
    )
  }

  const sidebarItems = ["LOGO", "COLOR"]

  return (
    <>
      <StaticQuery
        query={graphql`
          query BrandingPage {
            SuperOps {
              pages(where: { title: "Everything visual" }) {
                title
                subtitle
                content {
                  html
                }
                pageBlock {
                  content {
                    html
                  }
                }
                seo {
                  title
                  description
                  keywords
                  image {
                    url
                  }
                }
                links {
                  section
                  linkName
                  slug
                  authorName
                  icon {
                    url
                  }
                }

                ctaBoxes {
                  theme
                  type
                  heading {
                    html
                  }
                  primaryButtonText
                  primaryButtonLink
                  primaryLinkInNewTab
                }
              }
            }
          }
        `}
        render={data => (
          <>
            {data.SuperOps.pages.map(page => {
              const {
                title,
                subtitle,
                content,
                pageBlock,
                links,
                seo,
                ctaBoxes,
              } = page
              return (
                <div className="Branding-page">
                  <Frame
                    title={seo.title}
                    description={seo.description}
                    keywords={seo.keywords}
                    ogTitle={seo.title}
                    ogDescription={seo.description}
                    ogImage={seo.image}
                    ogType="website"
                  >
                    <header>
                      <Navigation page="Home" color="#FFECE8" />
                    </header>

                    <main>
                      <section className="hero">
                        <Container>
                          <center>
                            <Slide delay="200">
                              <div className="p14">
                                <p>{subtitle}</p>
                                <h1>{title}</h1>
                              </div>
                            </Slide>
                          </center>
                        </Container>
                      </section>

                      <Container>
                        <section className="top-section">
                          <Slide delay="200">
                            <div className="content">{parse(content.html)}</div>
                          </Slide>
                        </section>
                      </Container>

                      <Container>
                        <section className="main-section">
                          <Row>
                            <InPageDropDown links={sidebarItems} />

                            <Col lg={3} className="left-section">
                              <SidebarSmall
                                items={sidebarItems}
                                title="FOUNDATION"
                              />
                            </Col>

                            <Col lg={9} className="right-section">
                              <div id={0}>
                                <Slide delay="200">
                                  <div className="logo-guide p16">
                                    {parse(pageBlock[0].content.html)}
                                  </div>
                                </Slide>

                                <div className="do-section">
                                  <p className="heading">Do's</p>

                                  <div className="card-wrapper">
                                    <Slide delay="200">
                                      <Row>
                                        <ImageCard items={links[5]} />
                                      </Row>
                                    </Slide>
                                  </div>
                                </div>

                                <div className="dont-section">
                                  <p className="heading">Don'ts</p>

                                  <div className="card-wrapper">
                                    <Slide delay="200">
                                      <Row>
                                        <ImageCard
                                          items={links[6]}
                                          isBackground
                                        />
                                      </Row>
                                    </Slide>
                                  </div>
                                </div>
                              </div>

                              {/* CTA Box */}
                              <div className="download-cta">
                                <Slide delay="200">
                                  <CTA
                                    data={[ctaBoxes[0]]}
                                    leftCol="5"
                                    rightCol="5"
                                  />
                                </Slide>
                              </div>

                              {/* CTA */}

                              <div className="primary-color" id={1}>
                                <div className="content-wrapper">
                                  <Slide delay="200">
                                    <div className="primary-content">
                                      {parse(pageBlock[1].content.html)}
                                    </div>
                                  </Slide>
                                  <div className="primary-table">
                                    <Row>
                                      <Col lg={8} xs={12} md={8}>
                                        <Slide delay="200">
                                          <div className="p16 heading">
                                            <p>
                                              {parse(pageBlock[4].content.html)}
                                            </p>
                                          </div>
                                        </Slide>
                                      </Col>
                                      <Col
                                        className="text-center d-tab"
                                        lg={4}
                                        md={4}
                                      >
                                        <Slide delay="200">
                                          <div className="p16 heading">
                                            <p>
                                              {parse(pageBlock[5].content.html)}
                                            </p>
                                          </div>
                                        </Slide>
                                      </Col>
                                    </Row>

                                    <Row className="align-items-center">
                                      <Col md={8} lg={8} xs={12}>
                                        <Slide delay="200">
                                          <Row>
                                            <ColorCards
                                              items={links[0]}
                                              md={6}
                                            />
                                          </Row>
                                        </Slide>
                                      </Col>
                                      <Col
                                        className="text-center d-tab"
                                        lg={4}
                                        md={4}
                                      >
                                        <Slide delay="200">
                                          <div className="p16">
                                            <p>{parse(links[0].section)}</p>
                                          </div>
                                        </Slide>
                                      </Col>
                                    </Row>

                                    <Row className="align-items-center">
                                      <Col md={8} lg={8} xs={12}>
                                        <Slide delay="200">
                                          <Row>
                                            <ColorCards
                                              items={links[1]}
                                              md={6}
                                            />
                                          </Row>
                                        </Slide>
                                      </Col>
                                      <Col
                                        className="text-center d-tab"
                                        lg={4}
                                        md={4}
                                      >
                                        <Slide delay="200">
                                          <div className="p16">
                                            <p>{parse(links[1].section)}</p>
                                          </div>
                                        </Slide>
                                      </Col>
                                    </Row>

                                    <Row className="align-items-center">
                                      <Col lg={8} xs={12} md={8}>
                                        <Slide delay="200">
                                          <Row>
                                            <ColorCards
                                              items={links[2]}
                                              md={6}
                                            />
                                          </Row>
                                        </Slide>
                                      </Col>
                                      <Col
                                        className="text-center d-tab"
                                        lg={4}
                                        md={4}
                                      >
                                        <Slide delay="200">
                                          <div className="p16">
                                            <p>{parse(links[2].section)}</p>
                                          </div>
                                        </Slide>
                                      </Col>
                                    </Row>
                                  </div>
                                </div>

                                <div className="content-wrapper">
                                  <Slide delay="200">
                                    <div className="primary-content">
                                      {parse(pageBlock[2].content.html)}
                                    </div>
                                  </Slide>
                                  <Slide delay="200">
                                    <Row>
                                      <ColorCards
                                        items={links[3]}
                                        className="dark"
                                        lg={4}
                                      />
                                    </Row>
                                  </Slide>
                                </div>

                                <div className="content-wrapper">
                                  <Slide delay="200">
                                    <div className="primary-content">
                                      {parse(pageBlock[3].content.html)}
                                    </div>
                                  </Slide>
                                  <Slide delay="200">
                                    <Row>
                                      <ColorCards
                                        items={links[4]}
                                        className="dark"
                                        lg={4}
                                      />
                                    </Row>
                                  </Slide>
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </section>
                      </Container>

                      <div className="contact-cta">
                        <Slide delay="200">
                          <CTA data={[ctaBoxes[1]]} />
                        </Slide>
                      </div>

                      <Container
                        className="alert-wrapper"
                        style={{ opacity: showAlert ? "1" : "0" }}
                      >
                        <Alerts />
                      </Container>
                    </main>
                  </Frame>
                </div>
              )
            })}
          </>
        )}
      />
    </>
  )
}

export default Branding
